import React from 'react';

import map from 'lodash/fp/map';
import size from 'lodash/fp/size';

import Card from '@mui/material/Card';
import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';
import { useOmsSelector } from 'hooks/rtk';

import ErrorComponent from 'Components/ErrorComponent';
import CenteredProgress from '../Components/CenteredProgress';
import PageRoot from '../Components/PageRoot';

import * as TeammatesSelectors from 'dux/teammates/selectors';

const FlexContainer = styled('div')`
  display: flex;
`;

const StyledCard = styled(Card)`
  padding: 20px;
  min-height: 40px;
`;

const HomeScene = () => {
  const error = useOmsSelector(TeammatesSelectors.getError);
  const errorKind = useOmsSelector(TeammatesSelectors.getErrorKind);
  const loadingStatus = useOmsSelector(TeammatesSelectors.getStatus);
  const firstName = useOmsSelector(TeammatesSelectors.getSignedInTeammateFirstName);
  const lastName = useOmsSelector(TeammatesSelectors.getSignedInTeammateLastName);
  const username = useOmsSelector(TeammatesSelectors.getSignedInTeammateUsername);
  const groups = useOmsSelector(TeammatesSelectors.getSignedInTeammateGroups);

  if (error && errorKind === 'fetchSignedInTeammate') return <ErrorComponent error={error} />;
  if (loadingStatus === 'pending') return <CenteredProgress />;

  return (
    <PageRoot>
      <FlexContainer data-testid="welcome-page">
        <StyledCard>
          <Typography>
            Welcome{' '}
            <em>
              {firstName} {lastName}
            </em>{' '}
            !
          </Typography>
          <Typography>
            You are logged in as{' '}
            <code data-testid="welcome-username" style={{ backgroundColor: 'GainsBoro' }}>
              {username}
            </code>
          </Typography>
          <br />
          <Typography data-sentry-unmask>
            You are part of the following group{size(groups) !== 1 ? 's' : ''} :
          </Typography>
          <ul data-testid="welcome-groups" data-sentry-unmask>
            {map(
              ({ name }) => (
                <li key={name}>
                  <Typography>{name}</Typography>
                </li>
              ),
              groups
            )}
          </ul>
        </StyledCard>
      </FlexContainer>
    </PageRoot>
  );
};

export default HomeScene;
