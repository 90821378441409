import { createAsyncThunk } from '@reduxjs/toolkit';

import type { RootState } from 'store';

import initApiClient from 'services/ApiClient';

import logSentryError from 'utils/sentry';

import type { AuthToken } from 'dux/auth/types';
import type { Category } from 'dux/surveys/types';

import { getAuth } from './selectors';
import { getSignInToken, verifySigninToken } from './services';
import type { AuthParams, Customer, HairProfile, PatchHairProfile } from './types';

export const fetchCustomer = createAsyncThunk<Customer, void, { state: RootState }>(
  'fastconsultation/fetchCustomer',
  async (_, { dispatch, getState }) => {
    try {
      const APIClient = initApiClient(dispatch);
      const token = getAuth(getState());
      const headers = { Authorization: `Bearer ${token}` };
      const response = await APIClient.get('/v1/account/', null, headers);
      const customer = await response.json();
      return customer;
    } catch (err) {
      logSentryError('[fetchCustomer] fetch customer', err);
      throw err;
    }
  }
);

export const signinCustomer = createAsyncThunk<AuthToken, { pubkey: string }>(
  'fastconsultation/signinCustomer',
  async (pubkey, { dispatch }) => {
    try {
      const APIClient = initApiClient(dispatch);
      const response = await APIClient.get(`/v1/backoffice/customers/${pubkey}/`);
      const customer = await response.json();
      const token = getSignInToken(customer.auth_link);
      const authTokens = await verifySigninToken(token, APIClient);
      return authTokens;
    } catch (err) {
      logSentryError('[signinCustomer] sign in customer', err);
      throw err;
    }
  }
);

export const signupCustomer = createAsyncThunk<AuthToken, AuthParams>(
  'fastconsultation/signupCustomer',
  async (params, { dispatch }) => {
    try {
      const APIClient = initApiClient(dispatch);
      const response = await APIClient.post('/v1/auth/signup/', params);
      const data = await response.json();
      const authTokens = {
        accessToken: data.access,
        refreshToken: data.refresh,
      };
      return authTokens;
    } catch (err) {
      logSentryError('[signupCustomer] sign up customer', err);
      throw err;
    }
  }
);

export const fetchHairProfile = createAsyncThunk<HairProfile, void, { state: RootState }>(
  'fastconsultation/fetchHairProfile',
  async (_, { dispatch, getState }) => {
    try {
      const APIClient = initApiClient(dispatch);
      const token = getAuth(getState());
      const headers = { Authorization: `Bearer ${token}` };
      const response = await APIClient.get('/v1/account/hair_profile/', null, headers);
      const hairProfile = await response.json();
      return hairProfile;
    } catch (err) {
      logSentryError('[fetchHairProfile] fetch HairProfile', err);
      throw err;
    }
  }
);

export const patchHairProfile = createAsyncThunk<
  { hairProfile: HairProfile; category: Category },
  { newProfile: PatchHairProfile; category: Category },
  { state: RootState }
>('fastconsultation/patchHairProfile', async ({ newProfile, category }, { dispatch, getState }) => {
  try {
    const APIClient = initApiClient(dispatch);
    const token = getAuth(getState());
    const headers = { Authorization: `Bearer ${token}` };
    const response = await APIClient.patch('/v1/account/hair_profile/', newProfile, headers);
    const hairProfile = await response.json();
    return { hairProfile, category };
  } catch (err) {
    logSentryError('[patchHairProfile] patch HairProfile', err);
    throw err;
  }
});
