import React from 'react';
import PropTypes from 'prop-types';

import { Outlet } from 'react-router-dom';

import includes from 'lodash/fp/includes';
import isEmpty from 'lodash/fp/isEmpty';

import { useOmsSelector } from 'hooks/rtk';

import userHasRequiredPermissions from 'utils/userHasRequiredPermissions';

import * as TeammatesSelectors from 'dux/teammates/selectors';

import CenteredProgress from './CenteredProgress';
import ErrorComponent from './ErrorComponent';
import NotAllowedComponent from './NotAllowedComponent';

const PermissionRoute = ({ permissions }) => {
  const signedInPermissions = useOmsSelector(TeammatesSelectors.getSignedInTeammatePermissions);
  const signedInStatus = useOmsSelector(TeammatesSelectors.getStatus);

  if (includes(signedInStatus, ['idle', 'pending']) && isEmpty(signedInPermissions)) {
    return <CenteredProgress />;
  }
  if (signedInStatus === 'rejected') return <ErrorComponent />;

  if (isEmpty(signedInPermissions)) {
    return <div>You do not have any role assigned. Please, contact your manager</div>;
  }

  if (userHasRequiredPermissions(signedInPermissions, permissions)) {
    return <Outlet />;
  }
  return <NotAllowedComponent necessaryPermissions={permissions} />;
};

PermissionRoute.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string),
};

PermissionRoute.defaultProps = {
  permissions: [],
};

export default PermissionRoute;
